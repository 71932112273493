import LiveStreamsSection from './LiveStreamsSection';
import PageLayout from './PageLayout';
import withVerticalScroller from '../../components/withVerticalScroller';
import HeroSection from './HeroSection';
import { useUser } from '../../contexts/User';
import WhyChooseUs from './WhyChooseUs';
import Footer from '../../layouts/AppLayoutWithNavbar/Footer';
// import CallToActionMidPage from './CallToActionMidPage';
import FAQSection from './FAQSection';
import CookiePolicy from '../../components/CookiePolicy';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
// import Banner from './Banner';

const ChannelDirectory = () => {
  const { isSessionValid } = useUser();
  const { isMobileView } = useResponsiveDevice();

  return (
    <PageLayout>
      <div className="space-y-16">
        {!isSessionValid && <HeroSection />}
        <LiveStreamsSection />
        {!isSessionValid && (
          <>
            <WhyChooseUs />
            <FAQSection />
            <Footer />
          </>
        )}
        {!isMobileView && <CookiePolicy />}
      </div>
    </PageLayout>
  );
};

export default withVerticalScroller(ChannelDirectory);
