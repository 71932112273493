import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ProductsSection from './ProductsSection';
import { getActiveProducts } from '../../../api/products';
import { Search } from '../../../assets/icons';
import Dropdown from '../../../components/Dropdown';
import Spinner from '../../../components/Spinner';

// Custom hook for debouncing input values
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

// Function to sort products based on the selected key
const sortProducts = (products, key) => {
  const sortedProducts = [...products];
  switch (key) {
    case 'mostRecent':
      return sortedProducts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    case 'oldest':
      return sortedProducts.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    case 'productNameAsc':
      return sortedProducts.sort((a, b) => a.productName.localeCompare(b.productName));
    case 'productNameDesc':
      return sortedProducts.sort((a, b) => b.productName.localeCompare(a.productName));
    case 'priceLowToHigh':
      return sortedProducts.sort((a, b) => (a.salePrice || a.price) - (b.salePrice || b.price));
    case 'priceHighToLow':
      return sortedProducts.sort((a, b) => (b.salePrice || b.price) - (a.salePrice || a.price));
    default:
      return products;
  }
};

const ChannelProducts = ({ channelArn = '', username = '' }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortKey, setSortKey] = useState('mostRecent');
  const [error, setError] = useState(null);

  // Options for sorting the products
  const sortOptions = useMemo(
    () => [
      { value: 'mostRecent', label: 'Most Recent' },
      { value: 'priceLowToHigh', label: 'Price Low to High' },
      { value: 'priceHighToLow', label: 'Price High to Low' },
      { value: 'productNameAsc', label: 'Product Name A-Z' },
      { value: 'productNameDesc', label: 'Product Name Z-A' },
      { value: 'oldest', label: 'Oldest' },
    ],
    []
  );

  // Fetch active products when the channelArn changes
  useEffect(() => {
    if (!channelArn) return;
    setIsLoading(true);
    getActiveProducts(channelArn)
      .then((data) => {
        if (!data) {
          throw new Error('No data received');
        }
        setProducts(data.products || []);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setError(
          'We encountered an issue while fetching products. Please check your connection and try again.'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [channelArn]);

  // Handle changes in the search input
  const handleSearch = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  // Handle changes in the sort dropdown
  const handleSortChange = useCallback((event) => {
    setSortKey(event.target.value);
  }, []);

  // Debounce the search term to optimize performance
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Filter and sort products based on search term and selected sort key
  const filteredAndSortedProducts = useMemo(() => {
    const filteredProducts = products.filter((product) =>
      product?.productName?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
    return sortProducts(filteredProducts, sortKey);
  }, [products, sortKey, debouncedSearchTerm]);

  return (
    <div className="container mx-auto max-w-7xl py-8 px-4 sm:px-6 lg:px-8 bg-white dark:bg-black text-gray-900 dark:text-gray-100">
      {/* Header */}
      <div className="mb-8">
      
        <div className="flex  sm:flex-col flex-row items-center justify-between sm:space-y-4 space-y-0 space-x-4 sm:space-x-0">
          {/* Search Bar */}
          <div className="sm:w-full w-1/2">
            <div className="relative">
              <input
                type="text"
                id="product-search"
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 shadow-sm placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent"
                placeholder="Search for a product"
                value={searchTerm}
                onChange={handleSearch}
                aria-label="Search for a product"
              />
              <Search className="w-5 h-5 absolute text-gray-400 dark:text-gray-500 left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          {/* Sort Dropdown */}
          <div className="sm:w-full w-auto">
            <Dropdown
              id="sort-dropdown"
              aria-label="Sort products"
              selected={sortKey}
              options={sortOptions}
              onChange={handleSortChange}
              placeholder="Sort by"
              className="sm:w-full w-48 py-2 pl-4 pr-10 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="mb-8">
          <div
            className="bg-red-100 dark:bg-red-900 border border-red-200 dark:border-red-700 text-red-800 dark:text-red-200 px-4 py-3 rounded-lg"
            role="alert"
          >
            <p className="font-medium">Error:</p>
            <p>{error}</p>
          </div>
        </div>
      )}

      {/* Products Section or Loading Spinner */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <Spinner size="large" className="text-blue-500 dark:text-blue-400" />
        </div>
      ) : (
        <ProductsSection
          products={filteredAndSortedProducts}
          isLoading={isLoading}
          channelArn={channelArn}
          username={username}
        />
      )}
    </div>
  );
};

ChannelProducts.propTypes = {
  channelArn: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default ChannelProducts;
