import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';

// Reusable ChevronIcon Component
const ChevronIcon = ({ isOpen }) => (
  <svg
    className={`w-5 h-5 text-indigo-600 dark:text-indigo-400 transition-transform duration-300 ${
      isOpen ? 'rotate-180' : ''
    }`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    aria-hidden="true"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
);

ChevronIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

// Reusable FAQItem Component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-indigo-200 dark:border-indigo-700">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full py-4 text-left focus:outline-none"
        aria-expanded={isOpen}
        aria-controls={`faq-answer-${question}`}
      >
        <h4 className="text-lg font-medium text-indigo-900 dark:text-indigo-100">
          {question}
        </h4>
        <ChevronIcon isOpen={isOpen} />
      </button>
      <div
        id={`faq-answer-${question}`}
        className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
          isOpen ? 'max-h-96' : 'max-h-0'
        }`}
      >
        <p className="py-2 text-gray-600 dark:text-gray-300">{answer}</p>
      </div>
    </div>
  );
};

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

// FAQSection Component
const FAQSection = () => {
  const [showAll, setShowAll] = useState(false);

  const faqs = [
    {
      question: 'What is Livescape Shops?',
      answer:
        'Livescape Shops is a live shopping marketplace that connects buyers with a wide variety of products through interactive livestreams. Our platform allows you to discover diverse items from multiple sellers, engage directly with them, and make purchases live.'
    },
    {
      question: 'How do live shopping events work?',
      answer:
        'During a livestream, sellers showcase their products live. You can view items up close, ask questions in the chat, and make purchases directly from the stream. This interactive experience allows for a more personal and engaging shopping experience, similar to being in a physical store but from the comfort of your home.'
    },
    {
      question: 'Do I need an account to use Livescape Shops?',
      answer:
        'While you can browse and watch livestreams without an account, creating a free account unlocks the full Livescape Shops experience. With an account, you can make purchases, participate in live chats, follow favorite sellers, and receive personalized recommendations based on your interests.'
    },
    {
      question: 'What types of products can I find on Livescape Shops?',
      answer:
        "Livescape Shops features a wide variety of products across numerous categories. You'll find everything from electronics and fashion to home goods and handmade products. Our diverse marketplace offers both popular brands and unique items from independent sellers, catering to a broad range of shopping preferences."
    },
    {
      question: 'How do I know the products are authentic and of good quality?',
      answer: (
        <>
          The live shopping format allows you to see products in detail and ask
          questions directly to the seller. Additionally, we have a{' '}
          <Link
            to="https://help.livescapeshops.com/en/"
            className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300 underline"
          >
            Help Center
          </Link>{' '}
          where buyers can share their experiences, helping to maintain high
          standards across our platform.
        </>
      )
    },
    {
      question: 'How can I become a seller on Livescape Shops?',
      answer: (
        <>
          Becoming a seller on Livescape Shops is easy! You'll need to create an
          account, click on the become a seller link in the bottom left corner
          of the screen, link your account to payment processing for payouts,
          and start uploading your products. For a detailed guide on the
          process, including information about fees and best practices, check
          out our{' '}
          <Link
            to="https://help.livescapeshops.com/en/articles/9918987-how-to-become-a-seller-on-livescape-shops"
            className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300 underline"
          >
            Become a Seller Guide
          </Link>
          .
        </>
      )
    },
    {
      question: 'How does shipping and delivery work?',
      answer:
        "Shipping methods and costs vary by seller, as each is responsible for fulfilling their own orders. During the checkout process, you'll see available shipping options and costs. Many of our sellers offer tracking information once your item has been shipped, allowing you to follow your purchase from seller to your doorstep."
    }
  ];

  const displayedFaqs = showAll ? faqs : faqs.slice(0, 4);

  return (
    <section className="w-full max-w-7xl mx-auto  rounded-xl my-16">
      <div className="py-12 sm:py-16">
        <div className="max-w-4xl mx-auto px-6">
          <div className="flex flex-col items-center space-y-8">
            <h2 className="sm:text-2xl text-3xl font-bold text-indigo-900 dark:text-indigo-100 text-center mb-8">
              Frequently Asked Questions
            </h2>
            <div className="w-full space-y-4">
              {displayedFaqs.map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            </div>
            {faqs.length > 4 && (
              <div className="flex justify-center mt-8">
                <Button
                  variant="primary"
                  onClick={() => setShowAll(!showAll)}
                  className="px-6 py-3 sm:text-base text-lg bg-indigo-600 dark:bg-indigo-500 text-white dark:text-gray-100 hover:bg-indigo-700 dark:hover:bg-indigo-600 font-bold rounded-full shadow-md transition-all duration-300 ease-in-out"
                >
                  {showAll ? 'Show Less' : 'Show More'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
