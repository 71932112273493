import React from 'react';
import PropTypes from 'prop-types';
import { CommunityConnect, Deal, EngagingChat } from '../../assets/icons';

const FeatureCard = ({ icon, title, description }) => (
  <article className="group h-full">
    <div className="flex h-full flex-col items-center p-8 text-center rounded-2xl bg-gray-50/10 backdrop-blur-md shadow-xl transition-all duration-300 hover:bg-gray-50/20 border border-gray-50/10">
      <div className="mb-6 flex h-20 w-20 sm:h-16 sm:w-16 items-center justify-center rounded-full bg-gray-300/20">
        {React.cloneElement(icon, {
          className: 'w-12 h-12 text-white fill-white',
          'aria-label': `${title} Icon`
        })}
      </div>
      <h3 className="mb-4 text-lg font-bold text-white">{title}</h3>
      <p className="text-gray-200 flex-grow">{description}</p>
    </div>
  </article>
);

FeatureCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const WhyChooseUs = () => {
  const features = [
    {
      icon: <EngagingChat />,
      title: 'Live Streams',
      description: 'Enjoy entertaining live streams from a variety of sellers.'
    },
    {
      icon: <CommunityConnect />,
      title: 'Community',
      description:
        'Passionate sellers interact and build connections with like-minded buyers.'
    },
    {
      icon: <Deal />,
      title: 'Real-Time Deals',
      description: 'Many discounts available only during live streams.'
    }
  ];

  return (
    <section
      className="relative mx-auto w-full max-w-7xl mt-16 py-16 rounded-2xl bg-gradient-to-r from-violet-500 via-indigo-600 to-violet-500 shadow-xl"
      aria-labelledby="why-choose-us-title"
    >
      <div className="relative z-10 mx-auto max-w-6xl px-6">
        <header className="mb-16 text-center">
          <h2
            id="why-choose-us-title"
            className="text-3xl sm:text-2xl font-bold text-white"
            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}
          >
            Interactive Shopping Experience
          </h2>
        </header>
        <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 items-stretch">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
