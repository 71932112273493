import { useRef, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { clsm } from '../../utils';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
import { useUser } from '../../contexts/User';
import ConfirmationModal from '../../components/ConfirmationModal';
import FloatingNav from '../../components/FloatingNav';
import Navbar from './Navbar';
import useCurrentPage from '../../hooks/useCurrentPage';
import withSessionLoader from '../../components/withSessionLoader';
import MobileNavbar from './Navbar/MobileNavbar';

const AppLayoutWithNavbar = () => {
  const {
    isDefaultResponsiveView,
    isLandscape,
    isMobileView,
    mainRef,
    isTouchscreenDevice
  } = useResponsiveDevice();
  const { isSessionValid } = useUser();
  const currentPage = useCurrentPage();
  const appLayoutRef = useRef();
  const pageTypes = [
    'channel',
    'stream_manager',
    'settings',
    'cart',
    'channel_directory',
    'following',
    'channel_orders',
    'orders',
    'start_selling',
    'seller_payments',
    'products',
    'edit_product',
    'terms_and_conditions',
    'faq',
    'contact',
    'privacy_policy',
    'coming_soon'
  ];
  const [
    isChannel,
    isStreamManager,
    isSettings,
    isCart,
    isChannelDirectory,
    isFollowing,
    isChannelOrders,
    isOrders,
    isStartSelling,
    isSellerPayments,
    isProducts,
    isEditProduct,
    isTermsAndConditions,
    isFAQ,
    isContact,
    isPrivacyPolicy,
    isComingSoon
  ] = pageTypes.map((page) => currentPage === page);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  const renderNav = () => {
    if (isMobileView) {
      if (isSessionValid) {
        return <FloatingNav />;
      } else {
        return (
          <>
            <FloatingNav />
            <MobileNavbar />
          </>
        );
      }
    }
    return (
      <Navbar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        isSessionValid={isSessionValid}
      />
    );
  };
  

  useEffect(() => {
    if (
      isChannelDirectory ||
      isFollowing ||
      isCart ||
      isTermsAndConditions ||
      isPrivacyPolicy ||
      isFAQ ||
      isContact ||
      isComingSoon
    ) {
      setIsSidebarOpen(false);
    }

    if (isChannelDirectory && isSessionValid) {
      setIsSidebarOpen(true);
    }

    if (isFollowing && isSessionValid) {
      setIsSidebarOpen(true);
    }

    if (isChannel) {
      setIsSidebarOpen(false);
    }
  }, [isChannelDirectory, isFollowing, isChannel, isSessionValid, isCart, isTermsAndConditions, isFAQ, isContact, isPrivacyPolicy, isComingSoon]);

  return (
    <div
      ref={appLayoutRef}
      className={clsm([
        'flex',
        'min-h-screen',
        'overflow-auto',
        'relative',
        'scroll-smooth',
        'supports-overlay:overflow-overlay',
        isChannel && !isTouchscreenDevice && 'lg:no-scrollbar'
      ])}
    >
      <main
        ref={mainRef}
        id={`main-app-container${isDefaultResponsiveView ? '' : '-scrollable'}`}
        className={clsm(
          ['absolute', 'dark:bg-black', 'bg-white', 'right-0'],
          !isSidebarOpen
            ? [
                isChannelDirectory && 'w-[calc(100vw_-_0px)]',
                isCart && 'w-[calc(100vw_-_0px)]',
                isFollowing && 'w-[calc(100vw_-_0px)]',
                isChannel && 'w-[calc(100vw_-_64px)]',
                isSettings && 'w-[calc(100vw_-_240px)]',
                isStreamManager && 'w-[calc(100vw_-_240px)]',
                isChannelOrders && 'w-[calc(100vw_-_240px)]',
                isOrders && 'w-[calc(100vw_-_240px)]',
                isStartSelling && 'w-[calc(100vw_-_240px)]',
                isSellerPayments && 'w-[calc(100vw_-_240px)]',
                isProducts && 'w-[calc(100vw_-_240px)]',
                isEditProduct && 'w-[calc(100vw_-_240px)]',
                isTermsAndConditions && 'w-[calc(100vw_-_0px)]',
                isFAQ && 'w-[calc(100vw_-_0px)]',
                isContact && 'w-[calc(100vw_-_0px)]',
                isPrivacyPolicy && 'w-[calc(100vw_-_0px)]',
                isComingSoon && 'w-[calc(100vw_-_0px)]',
                'md:w-screen',
                isLandscape && 'touch-screen-device:lg:w-screen'
              ]
            : [
                isChannelDirectory && 'w-[calc(100vw_-_0px)]',
                isCart && 'w-[calc(100vw_-_0px)]',
                isFollowing && 'w-[calc(100vw_-_0px)]',
                isChannel && 'w-[calc(100vw_-_240px)]',
                isSettings && 'w-[calc(100vw_-_240px)]',
                isStreamManager && 'w-[calc(100vw_-_240px)]',
                isChannelOrders && 'w-[calc(100vw_-_240px)]',
                isOrders && 'w-[calc(100vw_-_240px)]',
                isStartSelling && 'w-[calc(100vw_-_240px)]',
                isSellerPayments && 'w-[calc(100vw_-_240px)]',
                isProducts && 'w-[calc(100vw_-_240px)]',
                isEditProduct && 'w-[calc(100vw_-_240px)]',
                isTermsAndConditions && 'w-[calc(100vw_-_0px)]',
                isFAQ && 'w-[calc(100vw_-_0px)]',
                isContact && 'w-[calc(100vw_-_0px)]',
                isPrivacyPolicy && 'w-[calc(100vw_-_0px)]',
                isComingSoon && 'w-[calc(100vw_-_0px)]',
                'w-[calc(100vw_-_240px)]',
                'lg:portrait:w-[calc(100vw_-_160px)]',
                'md:portrait:w-screen',
                isLandscape && [
                  'md:w-screen',
                  'touch-screen-device:lg:w-screen'
                ]
              ]
        )}
      >
        <ConfirmationModal />
        <Outlet context={{ appLayoutRef }} />
      </main>
      {renderNav()}
    </div>
  );
};

export default withSessionLoader(AppLayoutWithNavbar);
