import React from 'react';
import Button from '../../components/Button';

const CallToAction = () => {
  return (
    <section className="w-full max-w-6xl mx-auto px-6 py-12 sm:py-16">
      <div className=" rounded-3xl ">
        <div className=" p-6 sm:p-8">
          <div className="flex flex-col items-center text-center">
            <h2
              className="mb-6 sm:mb-6 sm:text-2xl text-3xl font-bold leading-tight text-white"
              style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}
            >
              Join Live Shopping Events
            </h2>
            {/* <p className="max-w-xl mb-6 sm:text-base text-lg text-gray-200">
              Discover unique items and connect with artisans in real-time.
            </p> */}
            <Button
              type="nav"
              variant="primary"
              to="/register"
              className="px-6 py-3 text-base sm:text-lg bg-white text-purple-700 hover:bg-gray-100 font-bold rounded-full shadow-xl transition-all duration-300 ease-in-out"
              aria-label="Start Shopping Live"
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(CallToAction);
