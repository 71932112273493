import PropTypes from 'prop-types';
import { Following, ShoppingCart, ListAlt, Home } from '../../../assets/icons';
import { app as $appContent } from '../../../content';
import { clsm } from '../../../utils';
import { useUser } from '../../../contexts/User';
import { useResponsiveDevice } from '../../../contexts/ResponsiveDevice';
import { useCart } from '../../../contexts/Cart';
import React, { useCallback, Suspense } from 'react';

const Button = React.lazy(() => import('../../../components/Button'));
const ProfileMenu = React.lazy(() => import('../../../components/ProfileMenu'));
const Searchbar = React.lazy(() => import('./Searchbar'));
const UserAvatar = React.lazy(() => import('../../../components/UserAvatar'));

const $content = $appContent.navbar;

const ProfileAvatar = React.memo(
  ({ avatarSrc, color, username, isTouchscreenDevice }) => (
    <UserAvatar
      avatarSrc={avatarSrc}
      profileColor={color}
      className="w-8 h-8 transition-transform group-hover:scale-105"
      username={username}
      alt={`Avatar of ${username}`}
    />
  )
);

ProfileAvatar.propTypes = {
  avatarSrc: PropTypes.string,
  color: PropTypes.string,
  username: PropTypes.string,
  isTouchscreenDevice: PropTypes.bool.isRequired
};

ProfileAvatar.defaultProps = {
  avatarSrc: '',
  color: '',
  username: 'User'
};

const CartButton = React.memo(({ cartItemCount }) => {
  const handleCartClick = () => {
    if (window.gtag) {
      window.gtag('event', 'view_cart', {
        'event_category': 'ecommerce',
        'event_label': 'cart_button',
        'value': cartItemCount
      });
    }
  };

  return (
    <Button
      type="nav"
      variant="icon"
      to="/cart"
      aria-label="View your shopping cart"
      className="relative p-2 text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
      onClick={handleCartClick}
    >
      <ShoppingCart className="w-6 h-6" aria-hidden="true" />
      {cartItemCount > 0 && (
        <span className="absolute top-2 right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
          {cartItemCount}
        </span>
      )}
    </Button>
  );
});

CartButton.propTypes = {
  cartItemCount: PropTypes.number.isRequired
};

const AuthButtons = React.memo(() => {
  const trackAuth = (action) => {
    if (window.gtag) {
      window.gtag('event', 'auth_action', {
        'event_category': 'engagement',
        'event_label': action
      });
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <Button
        type="nav"
        variant="secondary"
        to="/login"
        className="text-gray-700 bg-transparent dark:bg-transparent hover:text-gray-900 dark:text-gray-300 dark:hover:text-white hover:bg-transparent focus:bg-transparent dark:hover:bg-transparent"
        saveLocationFromState
        aria-label="Log in to your account"
        onClick={() => trackAuth('login')}
      >
        Log In
      </Button>
      <Button
        type="nav"
        variant="primary"
        to="/register"
        className="text-white dark:text-gray-100 bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
        saveLocationFromState
        aria-label="Create a new account"
        onClick={() => trackAuth('signup')}
      >
        Sign Up
      </Button>
    </div>
  );
});

const Navbar = () => {
  const { userData, isSessionValid } = useUser();
  const { isTouchscreenDevice, isMobileView } = useResponsiveDevice();
  const { avatarSrc, color, username } = userData || {};
  const { cartItemCount } = useCart();

  const handleToggle = useCallback((toggle) => {
    toggle();
  }, []);

  const trackNavigation = useCallback((destination) => {
    if (window.gtag) {
      window.gtag('event', 'navigation', {
        'event_category': 'engagement',
        'event_label': destination
      });
    }
  }, []);

  const trackProfileMenuInteraction = useCallback((action) => {
    if (window.gtag) {
      window.gtag('event', 'profile_menu', {
        'event_category': 'engagement',
        'event_label': action
      });
    }
  }, []);

  return (
    <nav
      className={clsm([
        'fixed',
        'top-0',
        'w-full',
        'z-50',
        'bg-white',
        'dark:bg-black',
        // 'shadow-sm',
        // 'dark:shadow-darkMode-gray-medium',
        'transition-all',
        'duration-300'
      ])}
      role="navigation"
      aria-label="Main navigation"
    >
      <div className=" mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo Section */}
          <div className="flex-shrink-0 flex items-center">
            <Suspense fallback={<div>Loading...</div>}>
              <Button
                to="/"
                type="nav"
                variant="primaryText"
                className="flex items-center space-x-2 hover:bg-transparent focus:bg-transparent dark:bg-transparent dark:hover:bg-transparent"
                aria-label="Go to homepage"
                onClick={() => trackNavigation('home')}
              >
                <img
                  className="h-16 w-auto dark:hidden hover:bg-transparent focus:bg-transparent dark:hover:bg-transparent dark:focus:bg-transparent"
                  src="/logo-ls.svg"
                  alt="Livescape Logo"
                  loading="lazy"
                />
                <img
                  className="h-16 w-auto hidden dark:block hover:bg-transparent focus:bg-transparent dark:hover:bg-transparent dark:focus:bg-transparent"
                  src="/logo-ls-dark.svg"
                  alt="Livescape Logo"
                  loading="lazy"
                />
              </Button>
            </Suspense>
          </div>

          {/* Search Bar */}
          <Suspense fallback={<div>Loading...</div>}>
            <Searchbar
              className="hidden md:flex flex-1 max-w-xl mx-4"
            />
          </Suspense>

          {/* Profile/Cart Section */}
          <div className="flex items-center space-x-4">
            {isSessionValid && !isMobileView ? (
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <CartButton cartItemCount={cartItemCount} />
                </Suspense>
                <ProfileMenu
                  asPortal
                  menuClassName="absolute top-14 right-4 w-56 mt-2 origin-top-right bg-white dark:bg-gray-800 rounded-md shadow-lg ring-1 ring-gray-600 ring-opacity-5 focus:outline-none"
                  navData={[
                    {
                      label: $content.home,
                      icon: <Home className="w-5 h-5" aria-hidden="true" />,
                      to: '/',
                      onClick: () => trackProfileMenuInteraction('home')
                    },
                    {
                      label: $content.following_list,
                      icon: <Following className="w-5 h-5" aria-hidden="true" />,
                      to: '/following',
                      onClick: () => trackProfileMenuInteraction('following')
                    },
                    {
                      label: $content.orders,
                      icon: <ListAlt className="w-5 h-5" aria-hidden="true" />,
                      to: '/orders',
                      onClick: () => trackProfileMenuInteraction('orders')
                    }
                  ]}
                >
                  {({ isOpen, toggle, toggleRef }) => (
                    <Button
                      aria-label={`${isOpen ? 'Close' : 'Open'} navigation menu`}
                      className={clsm(
                        'flex items-center space-x-2 text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white',
                        isOpen && 'text-gray-900 dark:text-white'
                      )}
                      onClick={() => handleToggle(toggle)}
                      variant="primaryText"
                      ref={toggleRef}
                    >
                      <ProfileAvatar
                        avatarSrc={avatarSrc}
                        color={color}
                        username={username}
                        isTouchscreenDevice={isTouchscreenDevice}
                      />
                      <span className="font-medium">{username}</span>
                    </Button>
                  )}
                </ProfileMenu>
              </>
            ) : (
              <Suspense fallback={<div>Loading...</div>}>
                <AuthButtons />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default React.memo(Navbar);
