import { memo } from 'react';
import Button from '../../components/Button';

// Add preload hint for video
if (typeof document !== 'undefined') {
  const preloadLink = document.createElement('link');
  preloadLink.rel = 'preload';
  preloadLink.as = 'video';
  preloadLink.href = '/ex-shop.mp4';
  document.head.appendChild(preloadLink);
}

const HeroSection = memo(function HeroSection() {
  return (
    <section
      className="w-full max-w-7xl bg-gradient-to-r from-violet-500 to-indigo-600 rounded-2xl shadow-xl mt-4 sm:mt-8 overflow-hidden mx-auto"
      aria-label="Hero Section"
    >
      <div className="backdrop-blur-sm bg-white/5 rounded-2xl py-12">
        <div className="max-w-6xl mx-auto flex flex-row sm:flex-col-reverse items-center px-8 sm:px-4">
          <div className="w-1/2 sm:w-full text-left sm:text-center">
            <div className="flex flex-col items-start sm:items-center space-y-4 mb-8">
              <div className="flex items-center space-x-2">
                <h1 
                  className="text-5xl sm:text-2xl md:text-3xl font-extrabold text-white leading-tight tracking-tight"
                  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}
                >
                  Livescape Shops
                </h1>
                <span className="bg-gray-50/20 text-white text-sm font-semibold px-3 py-1 rounded-full shadow-lg">
                  Beta
                </span>
              </div>
            </div>
            <p className="text-base text-gray-200 mb-8 max-w-lg">
              Experience shopping with interactive live streams. Discover amazing products, engage with sellers, and shop live.
            </p>
            <div className="flex justify-start sm:justify-center space-x-4 sm:space-x-0 sm:space-y-4 sm:flex-col">
              <Button
                type="nav"
                variant="primary"
                to="/register"
                className="inline-flex items-center px-8 py-4 bg-white text-purple-700 font-bold text-lg rounded-full shadow-xl hover:bg-purple-600 hover:text-white dark:hover:text-black focus:outline-none focus:ring-4 focus:ring-purple-400/50 transition-all duration-300 ease-in-out transform hover:scale-105"
                aria-label="Start Shopping Live"
              >
                Get Started
              </Button>
              <Button
                type="nav"
                variant="secondary"
                to="/LivescapeDemo"
                className="inline-flex items-center px-8 py-4 bg-gray-50/20 border-2 border-white text-white font-bold text-lg rounded-full shadow-xl hover:bg-white/10 focus:outline-none focus:ring-4 focus:ring-white/30 transition-all duration-300 ease-in-out transform hover:scale-105"
                aria-label="View Example Shop"
              >
                Example Shop
              </Button>
            </div>
          </div>
          <div className="w-1/2 sm:w-full flex justify-end sm:justify-center mb-0 sm:mb-8">
            <div className="relative w-full aspect-video rounded-xl shadow-2xl h-full m-2">
              <div
                aria-hidden="true"
                className="absolute inset-0 rounded-xl ring-1 ring-inset ring-white/20 bg-gradient-to-t from-black/60 to-transparent"
              />
              <video
                src="/ex-shop.mp4"
                className="w-full h-full object-cover rounded-xl"
                autoPlay
                muted
                playsInline
                loading="lazy"
                preload="none"
                fetchPriority="low"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default HeroSection;
