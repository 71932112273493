import { useCallback, useEffect, useState } from 'react';

import { channelDirectory as $channelDirectoryContent } from '../../content';
import { getAvatarSrc } from '../../helpers';
import { getLiveChannels } from '../../api/channels';
import { useNotif } from '../../contexts/Notification';
import ChannelCard from '../../components/ChannelCard';
import GridLayout from './GridLayout';
import useForceLoader from '../../hooks/useForceLoader';
import { useUser } from '../../contexts/User';
import { clsm } from '../../utils';
const $content = $channelDirectoryContent.live_streams_section;
const $channelPageNotifications = $channelDirectoryContent.notification;

const LiveStreamsSection = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();

  const { notifyError } = useNotif();
  const isLoadingForced = useForceLoader();

  const { channels: liveChannels } = data || {};
  const hasLiveChannels = !!liveChannels?.length;
  const isLoading = (!error && !data) || isLoadingForced;

  const getSectionData = useCallback(async () => {
    setData(undefined);
    setError(undefined);

    const { result, error: fetchError } = await getLiveChannels();

    setData(result);
    setError(fetchError);

    if (fetchError)
      notifyError(
        $channelDirectoryContent.notification.error.error_loading_streams
      );
  }, [notifyError]);

  useEffect(() => {
    getSectionData();
  }, [getSectionData]);

  const { isSessionValid } = useUser();

  return (
    <section
      className={clsm([
        'w-full  mx-auto px-4 lg:px-8 py-10 sm:py-8 bg-transparent dark:bg-black ',
        !isSessionValid && 'my-12 rounded-xl',
        isSessionValid && 'my-0 py-4 sm:my-8 sm:px-2'
      ])}
    >
      {/* <div className="text-center">
        <h2 className="text-3xl sm:text-2xl font-extrabold text-gray-800 dark:text-white mb-4">
          {$content.title}
        </h2>
        {/* <p className="text-lg text-gray-600 dark:text-gray-300 max-w-4xl mx-auto">
          Discover exciting live streams from talented creators and shop unique
          products in real-time.
        </p> 
      </div> */}

      <GridLayout
        className="gap-8 sm:gap-10"
        hasError={!!error}
        hasData={hasLiveChannels}
        isLoading={isLoading}
        loadingError={$channelPageNotifications.error.error_loading_streams}
        noDataText={$content.no_streams_available}
        tryAgainFn={getSectionData}
        tryAgainText={$content.try_again}
      >
        {hasLiveChannels &&
          liveChannels.map((data) => {
            const { color, username } = data;

            return (
              <div className="transform transition duration-300 hover:scale-105">
                <ChannelCard
                  avatarSrc={getAvatarSrc(data)}
                  bannerSrc={data?.channelAssetUrls?.banner}
                  color={color}
                  key={data.username}
                  username={username}
                  variant="live"
                  className="shadow-lg rounded-xl overflow-hidden"
                />
              </div>
            );
          })}
      </GridLayout>

      {/* {hasLiveChannels && (
        <div className="mt-16 text-center">
          <button
            className="px-8 py-4 bg-indigo-600 text-white text-lg font-semibold rounded-full hover:bg-indigo-700 transition-colors duration-300 shadow-md hover:shadow-lg"
            onClick={() => {/* Add logic to load more streams 
          >
            Discover More Live Streams
          </button>
        </div>
      )} */}
    </section>
  );
};

export default LiveStreamsSection;
